import React from "react"
import { Box, Text } from "theme-ui"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import ContentSection from "../../components/ContentSection"


const currentAffairs = () => {
  return (
    <Box>
      <SEO title="CurrentAffairs" />
      <Layout>
        <PageHeader>
          <Text
            sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
          >
            Current Affairs
          </Text>
        </PageHeader>
        <ContentSection
          header="Current Affairs"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign:"justify"
              }}
            >
              <Text sx={{ fontSize: [0, 3] }}>
                Current affairs encompass recent events and developments in
                various fields, such as politics, economics, and culture,
                staying informed about current affairs offers several benefits.
              </Text>

              <Text sx={{ fontSize: [0, 3] }} my={4}>
                1.{" "}
                <span style={{ fontWeight: "800" }}>
                  Informed Decision Making:{" "}
                </span>
                being aware of current events helps individuals make informed
                decisions in various aspects of life, from personal choices to
                professional and civic responsibilities.
              </Text>

              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                2. <span style={{ fontWeight: "800" }}>Global Awareness: </span>{" "}
                keeping up with current affairs enhances global awareness,
                allowing individuals to understand the interconnected nature of
                the world and the impact of events on an international scale
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                3.{" "}
                <span style={{ fontWeight: "800" }}>Critical Thinking: </span>
                Regular exposure to current affairs nurtures critical thinking
                skills by encouraging individuals to analyse information,
                evaluate sources, and form well-reasoned opinions.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                4.{" "}
                <span style={{ fontWeight: "800" }}>
                  Engagement in Conversations:
                </span>{" "}
                A good understanding of current events facilitates meaningful
                conversations with others, fostering a sense of community and
                shared knowledge.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                5.{" "}
                <span style={{ fontWeight: "800" }}>Civic participation: </span>{" "}
                nformed citizens are better equipped to participate in civic
                activities, including voting, community engagement, and advocacy
                for social issues.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                6.{" "}
                <span style={{ fontWeight: "800" }}>Career Advancement:</span>{" "}
                Knowledge of current affairs is often crucial in professional
                settings, providing a broader context for decision-making and
                helping individuals stay relevant in their careers.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                7. <span style={{ fontWeight: "800" }}>Educational Value:</span>{" "}
                Following current affairs contributes to ongoing learning, as it
                exposes individuals to new ideas, perspectives, and areas of
                interest.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                8. <span style={{ fontWeight: "800" }}>Preparedness: </span>{" "}
                Awareness of current events individuals to anticipate and adapt
                to changes, whether they are economic shifts, policy changes, or
                societal developments.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                It's important to note that benefits may vary depending on the
                specific position and level of government service
              </Text>
            </Box>
          }
        />
      </Layout>
    </Box>
  )
}
export default currentAffairs
